@font-face {
    font-family:'PlusJakarta-bold';
    src: url("./files/PlusJakartaDisplay-Bold.otf");
}

@font-face {
    font-family:'PlusJakarta-light';
    src: url("./files/PlusJakartaDisplay-Light.otf");
}

@font-face {
    font-family:'PlusJakarta-medium';
    src: url("./files/PlusJakartaDisplay-Medium.otf");
}

@font-face {
    font-family:'PlusJakarta-regular';
    src: url("./files/PlusJakartaDisplay-Regular.otf");
}

